
/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.markdown *:last-child {
  @apply pb-0 mb-0;
}

.markdown *:first-child {
  @apply pt-0 mt-0;
}

.markdown *:first-child figure {
  @apply pt-0 mt-0;
}

.markdown p,
.markdown ul,
.markdown ol,
.markdown blockquote {
  @apply my-8;
}

/* Default Typography */

.markdown h1 {
  @apply text-4xl font-bold mt-32 mb-16;
}

.markdown h2 {
  @apply text-3xl font-bold mt-24 mb-12;
}

.markdown h3 {
  @apply text-2xl font-semibold mt-16 mb-8;
}

.markdown h4 {
  @apply text-xl font-semibold mt-8 mb-4;
}

.markdown p {
  @apply text-sm md:text-lg leading-relaxed;
}

.markdown li {
  @apply py-2 ml-6 text-lg;
}

.markdown hr {
  @apply my-16 border-t-2 border-wall-300;
}

.markdown iframe {
  min-height: 512px;
}

/* Technical Typography */

.markdown.technical h1 {
  @apply text-3xl font-bold mt-32 mb-16;
}

.markdown.technical h2 {
  @apply text-2xl font-bold mt-24 mb-12;
}

.markdown.technical h3 {
  @apply text-xl font-semibold mt-16 mb-8;
}

.markdown.technical h4 {
  @apply text-lg font-semibold mt-8 mb-4;
}

.markdown.technical p {
  @apply text-base leading-relaxed;
}

.markdown.technical li {
  @apply py-2 ml-6 text-base;
}

/* Imagery */

.markdown img {
  @apply rounded-lg w-full h-auto;
}

.markdown figure {
  @apply relative my-8 z-0 w-full left-0;
}

@media (min-width: 768px) {
  .markdown figure {
    width: 125%;
    left: -12.5%;
  }
}

.markdown pre,
.markdown pre[class*="language-"] {
}

.markdown code,
.markdown code[class*="language-"] {
  @apply px-2 py-0.5 bg-wall-100 rounded-md;
}

.markdown a code,
.markdown a code[class*="language-"] {
  @apply bg-green-100 text-green-400;
}

.markdown pre code,
.markdown pre[class*="language-"] code {
  @apply p-4 md:p-8 rounded-xl;
}

.markdown ul {
  list-style: outside;
  list-style-type: disc;
}

.markdown ol {
  list-style: outside;
  list-style-type: decimal;
}

.markdown li a,
.markdown h3 a,
.markdown h2 a {
  @apply text-green-400;
}

.markdown blockquote {
  @apply border-l-4 border-wall-300 pl-6 italic;
}
.markdown blockquote cite {
  @apply not-italic font-semibold;
}

.markdown table {
  border-collapse: separate;
  border-spacing: 0;
  min-width: 350px;
}

.markdown table tr th,
.markdown table tr td {
  border-right-width: 2px;
  border-right-style: solid;
  border-bottom-width: 2px;
  border-bottom-style: solid;
  padding: 8px !important;
  @apply border-wall-100;
}

.markdown table tr th:first-child,
.markdown table tr td:first-child {
  border-left-width: 2px;
  border-left-style: solid;
  @apply border-wall-100;
}

.markdown table tr th {
  background: rgba(240, 239, 236, 0.33);
  border-top-width: 2px;
  border-top-style: solid;
  text-align: left;
  vertical-align: middle;
  @apply border-wall-100;
}

/* top-left border-radius */
.markdown table tr:first-child th:first-child {
  border-top-left-radius: 8px;
}

/* top-right border-radius */
.markdown table tr:first-child th:last-child {
  border-top-right-radius: 8px;
}

/* bottom-left border-radius */
.markdown table tr:last-child td:first-child {
  border-bottom-left-radius: 8px;
}

/* bottom-right border-radius */
.markdown table tr:last-child td:last-child {
  border-bottom-right-radius: 8px;
}

@import url("https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&family=Source+Code+Pro:wght@400;500;600;700&display=swap");

:root {
  --tray-time: 0ms;
}

html,
body {
  padding: 0;
  margin: 0;
  @apply text-wall-600;
}

a {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}

b,
strong {
  @apply font-semibold;
}

p a {
  @apply text-green-400 font-semibold;
}

button {
  border: none;
}

/* Typography */

h1,
.type-h1 {
  @apply text-3xl md:text-4xl lg:text-5xl font-bold leading-tight text-wall-600;
}

h2,
.type-h2 {
  @apply text-2xl md:text-3xl lg:text-4xl font-bold leading-tight;
}

h3,
.type-h3 {
  @apply text-lg md:text-xl lg:text-2xl font-semibold;
}

h4,
.type-ui {
  @apply text-sm md:text-base lg:text-lg font-semibold;
}

.type-ui-small {
  @apply text-xs lg:text-base font-medium;
}

p,
.type-p {
  @apply text-sm md:text-base lg:text-base leading-relaxed;
}

.type-bold {
  @apply text-sm md:text-base lg:text-base font-semibold leading-relaxed;
}

.type-sub {
  @apply text-xs md:text-sm;
}

.type-sub-bold {
  @apply text-xs md:text-sm font-semibold;
}

code .type-code {
  @apply bg-wall-100 p-4 font-mono;
}

/* Utils */

.layout-narrow {
  @apply w-full md:w-8/12 lg:w-6/12;
}

.layout {
  @apply w-full md:w-10/12 lg:w-8/12;
}

.measure {
  max-width: 34em;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.bg-img {
  background-repeat: no-repeat;
  background-position: center center;
  @apply bg-wall-100;
}

.button-sm {
  @apply flex-center px-3 h-10 rounded-lg type-ui;
}

.button-lg {
  @apply flex-center px-4 lg:px-6 py-1 rounded-xl h-10 md:h-14 type-ui;
}

.badge-sm {
  @apply flex-center type-ui-small px-4 lg:px-4 h-8 md:h-8 rounded-full;
}

.badge-lg {
  @apply flex-center type-ui px-4 lg:px-8 h-10 md:h-12 rounded-full;
}

.button-sm:hover,
.button-text:hover,
.button-lg:hover,
a:hover {
  opacity: 0.66;
}

/* BEM Classes - Use sparingly */

.port-hero-card-height {
  height: 36em;
}

.port-hero-image-height {
  height: 32em;
}

.port-hero-image {
  transform: translateX(8%);
  background-size: cover;
}

.tray-menu-open {
  transform: translateX(0);
  transition: transform var(--tray-time);
}

.tray-menu-closed {
  transform: translateX(-110vw);
  transition: transform var(--tray-time);
}

.tray-menu-width {
  width: calc(100vw - 4rem - 1rem - 1rem);
}

.mobile-search-button-width {
  width: calc(100vw - 4rem - 1rem - 1rem - 1rem - 1rem);
}

.tray-overlay-open {
  opacity: 1;
  transition: opacity var(--tray-time);
}

.tray-overlay-closed {
  opacity: 0;
  transition: opacity var(--tray-time);
}

.menu-open {
  left: 0;
  visibility: visible;
  display: block;
}

.menu-closed {
  left: -100vw;
  visibility: hidden;
  display: none;
}

.dot::before {
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 99px;
  content: "";
  position: absolute;
  left: -0.75rem;
  top: 0.4rem;
  @apply bg-green-400;
}

.sidebar figure,
.sidebar img {
  width: auto !important;
  margin: 0 auto !important;
  left: 0 !important;
  display: block !important;
  position: static !important;
  border-radius: 0 !important;
}



